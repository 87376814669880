
import { ComputedRef, defineComponent, PropType, computed, watch } from "vue";
import { Router, useRouter } from "vue-router";
import {
  ITableBodyRow,
  ITableHeadCell,
  tableRowBgStatus,
  tableRowStatuses
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import {
  AccountStatus,
  IUserAccount
} from "@/store/interfaces/accounts/IUserAccount";
import { accountStatuses } from "@/enums/accounts/accountStatuses";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { userId } from "@/store/interfaces/auth/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";

type tableHeadCells = ITableHeadCell[];

export type accountsTableSort = {
  byName: sortItem;
  byLoginCountry: sortItem;
  byBalance: sortItem;
};

export default defineComponent({
  name: "AccountsTable",
  components: { AppTable },
  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    accountsList: {
      type: Array as PropType<IUserAccount[]>,
      required: true
    },

    sort: {
      type: Object as PropType<accountsTableSort>,
      required: true
    },

    initialViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },

  emits: {
    toggleBlock: null,
    delete: null,
    changeViewConfiguration: null
  },
  setup(props, { emit }) {
    // base
    const rowDetailsBaseUrl = "/user";
    const rowDetailsQueryKey = "userId";

    const router: Router = useRouter();

    const initialSortOptions: accountsTableSort = {
      byName: {
        order: sortOrders.turnOff,
        keyName: "fullName",
        keyNameInModel: "fullName"
      },
      byLoginCountry: {
        order: sortOrders.turnOff,
        keyName: "loginCountry",
        keyNameInModel: "loginCountry"
      },
      byBalance: {
        order: sortOrders.turnOff,
        keyName: "balance",
        keyNameInModel: "balance.amount"
      }
    };

    // row actions
    const userEditHandler = (id: userId) => rowClickHandler(id);
    const userDeleteHandler = (id: userId) => emit("delete", id);
    const userToggleBlockHandler = (id: userId) => emit("toggleBlock", id);

    async function rowClickHandler(id: userId): Promise<void> {
      await router.push({
        path: rowDetailsBaseUrl,
        query: {
          [rowDetailsQueryKey]: id
        }
      });
    }

    // table model
    const accountsList: ComputedRef<IUserAccount[]> = computed(
      () => props.accountsList
    );

    const tableRefs = useBaseTable({
      initialViewConfiguration: { ...props.initialViewConfiguration },
      initialSortOptions,
      model: accountsList,
      generateRows(accountsList: IUserAccount[]): ITableBodyRow[] {
        return accountsList.map(
          (account: IUserAccount): ITableBodyRow => {
            const {
              id,
              blocked,
              emailVerified,
              firstName,
              lastName,
              email,
              image,
              application,
              balance
            } = account;

            const isVerified: boolean = emailVerified;
            let status: AccountStatus = accountStatuses.verified;
            let bgStatus: tableRowBgStatus = "";

            if (blocked) {
              status = accountStatuses.blocked;
              bgStatus = tableRowStatuses.danger;
            } else if (!isVerified) {
              status = accountStatuses.unverified;
              bgStatus = tableRowStatuses.warning;
            }
            return {
              status,
              bgStatus,
              onClick: (): void => {
                userEditHandler(id);
              },

              actions: [
                {
                  text: "Edit",
                  onClick: (): void => {
                    userEditHandler(id);
                  }
                },
                {
                  text: "Remove",
                  onClick: (): void => {
                    userDeleteHandler(id);
                  }
                },
                {
                  text:
                    status === accountStatuses.blocked ? "Unblock" : "Block",
                  onClick: () => {
                    userToggleBlockHandler(id);
                  }
                }
              ],

              cells: [
                {
                  infoWithImage: {
                    title: email,
                    description: email,
                    image: image || ""
                  }
                },
                { label: firstName },
                { label: lastName, isStatusText: true },
                { label: application?.title },
                {
                  label: `$${balance?.amount || 0}`
                },
                { label: status, isStatusText: true }
              ]
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    watch(
      props.initialViewConfiguration,
      (updatedConfiguration: ITableViewConfiguration) => {
        changeViewConfiguration(updatedConfiguration);
      }
    );

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  computed: {
    tableHead(): tableHeadCells {
      const { byName } = this.currentSort;

      return [
        {
          size: "xl",
          sort: {
            keyName: byName.keyName,
            order: byName.order
          },

          label: "Email",
          id: "user-name"
        },
        {
          size: "md",
          label: "First Name",
          id: "user-firstName"
        },
        {
          size: "md",
          label: "Last Name",
          id: "user-lastName"
        },
        {
          size: "md",
          label: "Application",
          id: "user-application"
        },
        {
          size: "sm",
          label: "Credit balance",
          id: "user-credit"
        },
        {
          size: "xs",
          label: "Status",
          id: "user-status"
        }
      ];
    }
  }
});
