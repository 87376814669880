
import { defineComponent, computed } from "vue";
import { validator } from "@/validator/Validator";
import { IValidationResult } from "@/validator/interfaces/common";
import { IInputError } from "@/components/Form/interfaces/validation/IInputError";

type providerInput = {
  id: string;
  input: HTMLInputElement;
  validationResult: IInputError | undefined;
};

type ProviderData = {
  validator: any;
  inputErrors: IInputError[];
  // eslint-disable-next-line no-undef
  slots: NodeListOf<HTMLInputElement> | [];
  inputs: providerInput[];
};

type providerRefs = {
  _slot: HTMLElement;
};

export default defineComponent({
  name: "AppInputsValidationProvider",

  provide() {
    return {
      inputErrors: computed(() => this.inputErrors),
      destroyed: this.destroyedHandler
    };
  },

  props: { shakeTree: { type: Boolean, default: false } },
  emits: {
    changeErrorState: null
  },

  data() {
    return {
      validator: null,
      inputErrors: [],
      slots: [],
      inputs: []
    } as ProviderData;
  },

  computed: {
    hasError(): boolean {
      return this.inputErrors.some(({ error }) => error);
    }
  },

  watch: {
    hasError: {
      handler(err: boolean): void {
        this.$emit("changeErrorState", err);
      },

      immediate: true
    },

    shakeTree(needShake) {
      if (needShake) {
        validator.shakeTree();
      }
    },

    slots: {
      handler(slots) {
        if (!slots.length) {
          return [];
        }

        const inputs: HTMLInputElement[] = Array.from(slots);

        this.inputs = inputs.map(
          (input: HTMLInputElement): providerInput => {
            const id: string = input.id;

            return {
              id,
              input,
              validationResult: this.inputErrors.find(field => field.id === id)
            };
          }
        );
      },
      immediate: true
    },
    inputs: {
      handler(
        inputs: providerInput[],
        prevInputs: providerInput[] | undefined
      ) {
        if (prevInputs) {
          this.destroyValidators(prevInputs);
        }

        // update current inputs

        if (inputs.length) {
          inputs.forEach(({ input, id }) => {
            validator.attachInput(input, (valid?: IValidationResult): void => {
              if (valid) {
                const { error, touched, errorMessage } = valid;

                const exist: IInputError | undefined = this.inputErrors.find(
                  (input: IInputError) => {
                    return input.id === id;
                  }
                );

                if (exist) {
                  exist.error = error;
                  exist.touched = touched;
                  exist.errorMessage = errorMessage;
                } else {
                  this.inputErrors.push({ id, error, touched, errorMessage });
                }
              }
            });
          });
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.setSlots();
  },

  beforeUnmount() {
    this.slots = [];
    this.inputErrors = [];
    this.destroyedHandler();
  },

  methods: {
    setSlots(): void {
      if (!this.$refs._slot) {
        this.slots = [];

        return;
      }

      const $slot = (this.$refs as providerRefs)._slot;
      this.slots = $slot.querySelectorAll("input") || [];
    },

    destroyedHandler(): void {
      this.setSlots();

      this.$nextTick(() => {
        this.destroyValidators(this.inputs, true);
      });
    },

    destroyValidators(inputs: providerInput[], removeAll = false) {
      const currentInputs = removeAll ? [] : this.inputs;
      const currentInputsIds: string[] = currentInputs.map(
        ({ id }: providerInput) => id
      );

      const destroyedInputs: providerInput[] = inputs.filter(
        ({ id }: providerInput) => {
          return !currentInputsIds.includes(id);
        }
      );

      destroyedInputs.forEach(({ id }: providerInput) => {
        validator.removeInput(id);

        this.inputErrors = this.inputErrors.filter(
          (input: IInputError) => input.id !== id
        );
      });
    }
  }
});
