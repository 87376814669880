import { ref, Ref } from "vue";
import { useStore } from "vuex";
import { AddUserModalDto, userTypes } from "@/api/interfaces/users/users.dto";
import { userExtraInfo } from "@/hooks/tables/modules/usersTable/usersTable";
import { ICreateUser } from "@/hooks/tables/modules/usersTable/types";

export function useCreateUser(): ICreateUser {
  const store = useStore();

  const creationLoading: Ref<boolean> = ref(false);
  const createUserDialogOpened: Ref<boolean> = ref(false);
  const onCreateSubmitResolved: Ref<Function | null> = ref(null);

  return {
    creationLoading,
    createUserDialogOpened,

    // methods
    onCreateSubmitResolved,
    setOnCreateSubmitResolved(onResolve: Function) {
      onCreateSubmitResolved.value = onResolve;
    },

    showCreateUserDialog() {
      createUserDialogOpened.value = true;
    },

    async createUser(
      data: AddUserModalDto,
      extra: userExtraInfo = { type: userTypes.user },
      onUserCreated?: Function
    ) {
      creationLoading.value = true;
      const { avatar, ...userInfo } = data;
      const { success, message, data: userId } = await store.dispatch(
        "addUser",
        {
          data: { ...userInfo, type: extra.type }
        }
      );

      if (!success && message) {
        await store.dispatch("showErrorNotification", message);
      }

      onCreateSubmitResolved.value?.(success);

      if (avatar) {
        await store.dispatch("uploadUserAvatar", {
          avatar: avatar,
          id: userId
        });
      }

      onUserCreated?.();
      createUserDialogOpened.value = false;
      creationLoading.value = false;
    }
  };
}
